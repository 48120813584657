import React, { useEffect } from 'react';
import Head from 'next/head';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import TagManager from 'react-gtm-module';
import AdsContextProvider from '@src/context/ads';
import ThemeContextProvider from '@src/context/theme';
import getGlobalCleverpush from '@src/components/Cleverpush/sdk';
import { ContentRoute, MenuTree } from '@src/interfaces';
import '@src/styles/app.scss';
import { useRouter } from 'next/router';
import Script from 'next/script';

const SOCIALS = {
  twitter: 'https://twitter.com/puls24news',
  instagram: 'https://www.instagram.com/puls24news/',
  facebook: 'https://www.facebook.com/puls24news',
  tiktok: 'https://www.tiktok.com/@puls24news',
  bluesky: 'https://bsky.app/profile/puls24.bsky.social',
};

// Adds "media" to <meta> attributes
declare module 'react' {
  interface MetaHTMLAttributes<T> extends HTMLAttributes<T> {
    media?: string;
  }
}

type P24AppProps = {
  route: ContentRoute;
  menu: MenuTree;
}


const MyApp = ({ Component, pageProps }: AppProps<P24AppProps>) => {
  const router = useRouter();
  useEffect(() => {
    if (!router.asPath.includes('nocleverpush=1')) {
    getGlobalCleverpush().then(sdk =>
      sdk?.push([
        'getSubscriptionId',
        (subscriptionId: unknown) => {
          console.debug('CleverPush Subscription ID', subscriptionId);
        },
      ]),
    );
    }
  }, [router]);

  useEffect(() => {
    const { publicRuntimeConfig } = getConfig();

    if (publicRuntimeConfig.GTM_ID) {
      const tagManagerArgs = {
        gtmId: publicRuntimeConfig.GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  useEffect(() => {
    const supportsContainerQueries = 'container' in document.documentElement.style;

    if (!supportsContainerQueries) {
      import('container-query-polyfill');
    }
  }, []);

  return (
    <>
      <Head>
        <link href="/favicon.ico" rel="icon" type="image/x-icon" />
        <link href="/assets/apple-touch-icon-v2.png" rel="apple-touch-icon" sizes="180x180" />
        <link href="/assets/favicon-32x32-v2.png" rel="icon" sizes="32x32" type="image/png" />
        <link href="/assets/favicon-16x16-v2.png" rel="icon" sizes="16x16" type="image/png" />
        <link href="/assets/site.webmanifest" rel="manifest" />
        <link color="#1a2b80" href="/assets/safari-pinned-tab.svg" rel="mask-icon" />

        <meta content="#1a2b80" media="(prefers-color-scheme: light)" name="theme-color" />
        <meta content="#242468" media="(prefers-color-scheme: dark)" name="theme-color" />
        <meta content="PULS 24" name="apple-mobile-web-app-title" />
        <meta content="PULS 24" name="application-name" />
        <meta content="#1a2b80" name="msapplication-TileColor" />
        <meta content="/assets/browserconfig.xml" name="msapplication-config" />

        <meta content="initial-scale=1.0, width=device-width" name="viewport" />

      </Head>

      {!router.asPath.includes('noebx=1') && (
      <Script async id="ebx" src="//applets.ebxcdn.com/ebx.js" strategy="afterInteractive" />
      )}
      
      <ThemeContextProvider>
        <AdsContextProvider config={pageProps.route?.meta?.somtag}>
          <Component {...pageProps} socials={SOCIALS} />
        </AdsContextProvider>
      </ThemeContextProvider>
    </>
  );
};

export default MyApp;
