import type { FC, ReactNode } from 'react';
import type { SomtagSlot, Somtag as SomtagConfig } from '@src/interfaces';
import type { SomMarketing } from '@p7s1/oasis-types/src/content-resource';
import type { View } from '@src/utils/cmp';
import React, { useState, useEffect, useMemo, useCallback, createContext } from 'react';
import getConfig from 'next/config';
import clientSlot from '@src/utils/somtag_clientslot';
import { loadScript } from '@src/utils/scripts';
import { useRouter } from 'next/router';

export type SomMarketingValues = {
  advertiser: string;
  config: {
    id: string;
    consent: {
      tcfVersion: 2;
      gdprConsent?: string;
    };
  };
  syndication: {
    content: '';
    partner: '';
  };
  display: {
    slots: {
      [k in SomtagSlot]?: { enabled: boolean };
    };
  };
} & SomMarketing;

export interface SomtagCallbackResult {
  type: string;
  data: {
    adConfig: {
      height: number | undefined;
      slot: string;
    };
    slotName: string;
    options: {
      container: string;
    };
  };
}

export const somMarketingDefault: SomMarketingValues = {
  advertiser: 'SOM_AT',
  config: {
    id: 'puls24at',
    consent: {
      tcfVersion: 2,
    },
  },
  display: {
    slots: {},
  },
  syndication: {
    content: '',
    partner: '',
  },
  taxonomy: {
    channels: ['opt-ros'],
    content: 'content',
    topics: {},
  },
};

export interface Somtag {
  cmd(
    string1: string,
    string2?: string | string[] | typeof somMarketingDefault,
    config?: {
      container: string;
    },
    callback?: (_: string, result: SomtagCallbackResult) => void,
  ): void;
}

export type AdsContextType = {
  adsEnabled: boolean;
  somtagInitialized: boolean;
  somtagInsert: (adSlot: string, containerId: string, callback: (height: number) => void) => void,
  somtagReload: (adSlot?: string) => void,
};

export const AdsContext = createContext<AdsContextType>({
  adsEnabled: false,
  somtagInitialized: false,
  somtagInsert: () => null,
  somtagReload: () => null,
});

type AdsContextProviderProps = {
  config: SomtagConfig;
  children: ReactNode;
};

const AdsContextProvider: FC<AdsContextProviderProps> = ({
  children,
  config,
}) => {
  const [somtag, setSomtag] = useState<Somtag>();
  const [somtagInitialized, setSomtagInitialized] = useState<boolean>(false);
  const [cookieConsent, setCookieConsent] = useState<boolean>(false);
  const router = useRouter();

  const somtagInit = useCallback(() => {
    const displaySlots: SomMarketingValues['display']['slots'] = {};

    Object.keys(config.slots).forEach(display => {
      const slots = config.slots[display as 'mobile' | 'desktop'] as SomtagSlot[];
      slots
        .concat(['skyscraper1', 'fullbanner2', 'mbanner1'])
        .map(slot => clientSlot(slot, [display]))
        .filter(slot => slot !== undefined)
        .forEach(slot => {
          displaySlots[slot as SomtagSlot] = { enabled: true };
        });
    });

    const initConfig: SomMarketingValues = {
      ...somMarketingDefault,
      ...{
        display: {
          slots: displaySlots,
        },
      },
      ...{
        taxonomy: {
          channels: config.taxonomy,
          content: 'content',
          topics: {},
        },
      },
    };

    const intervalID = setInterval(() => {
      if (window.somtag) {
        window.somtag.cmd('init', initConfig);
        setSomtag(window.somtag);
        setSomtagInitialized(true);
        clearInterval(intervalID);
      }
    }, 100);
  }, [config]);

  const somtagInsert = useCallback((adSlot: string, containerId: string, callback: (height: number) => void) => {
    somtag?.cmd('insertAd', adSlot, { container: `#${containerId}` }, (_, result) => {
      if (result.type === 'adResponse') {
        callback(result.data.adConfig.height ?? 0);
      }
    });
  }, [somtag]);

  const somtagReload = useCallback((adSlot?: string) => {
    if (adSlot) {
      somtag?.cmd('reloadDisplaySlots', [adSlot]);
    } else {
      somtag?.cmd('reloadDisplaySlots');
    }
  }, [somtag]);

  useEffect(() => {
    const handleConsent = ({ detail }: CustomEvent<{ view?: View }>) => {
      if (!detail || detail.view === 'configuration') {
        document.removeEventListener('cmp:initialized', handleConsent as EventListener);
        document.removeEventListener('cmp:confirmed', handleConsent as EventListener);
        document.removeEventListener('cmp:saved', handleConsent as EventListener);

        setCookieConsent(true);
      }
    }

    document.addEventListener('cmp:initialized', handleConsent as EventListener);
    document.addEventListener('cmp:confirmed', handleConsent as EventListener);
    document.addEventListener('cmp:saved', handleConsent as EventListener);
  }, []);

  // Must trigger new init on every page navigation so insertAd can be called again
  // See: https://sevenonemedia.github.io/adtec-se-docs/docs/desktop/desktop-faq.html#im-running-a-single-page-website-can-i-just-call-insertad-again-for-already-requested-ad-slots-in-order-to-replace-some-ads
  useEffect(() => {
    if (config?.enabled && cookieConsent && !router.asPath.includes('noads=1')) {
      loadScript(
        '/assets/somtag_loader.js'
      ).then(() => {
        setSomtagInitialized(false); // Prevent somtagInsert before re-init on navigation
        somtagInit();
      });
    }
  }, [config, cookieConsent, router.asPath, somtagInit]);

  const contextValue = useMemo(
    () => ({
      adsEnabled: getConfig().publicRuntimeConfig.SOMTAG_ENABLED && config?.enabled,
      somtagInitialized,
      somtagInsert,
      somtagReload,
    }),
    [config?.enabled, somtagInsert, somtagReload, somtagInitialized],
  );

  return (
    <AdsContext.Provider value={contextValue}>
      {children}
    </AdsContext.Provider>
  );
}

export default AdsContextProvider;
